<template>
  <v-app dark>
    <v-app-bar app dark color="rgb(36, 83, 120)">
      <div class="d-flex align-center">
        <!-- <v-icon x-large class="mr-4">mdi-map-clock</v-icon> -->
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        <!-- TODO transforma o titulo do app em componente -->
        <v-container class="d-inline d-sm-none pa-0 ma-0 align-left">
          <v-row no-gutters class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              Diário de Bordo
            </v-col>
          </v-row>
          <v-row no-gutters class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <span
                style="height:auto"
                class="pa-0 ma-0 subtitle-2 font-weight-light"
                >do motorista</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-container class="d-none d-sm-inline pa-0 ma-0 align-left">
          <v-row no-gutters class="pa-0 ma-0">
            <v-col cols="10" class="pa-0 ma-0">
              <h1 class="display-1">
                Diário de Bordo
              </h1>
            </v-col>
            <v-col cols="2" class="d-none d-sm-flex ">
              <span class="pa-0 ma-0  font-weight-light" style="height:auto"
                >do motorista</span
              >
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-spacer></v-spacer>
      <v-switch
        v-model="color"
        class="mt-6"
        :prepend-icon="color ? icons.mdiBrightness2 : icons.mdiBrightness5"
      ></v-switch>
      <v-btn text @click="logout">
        <span class="mr-2">sair</span>
        <v-icon>{{ icons.mdiDoorClosedLock }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-snackbar
        v-model="showAlert"
        :color="alert?.error ? 'error' : alert?.syncing ? 'info' : 'success'"
        :timeout="3000"
        top
      >
        <v-progress-circular
          v-if="alert && alert?.syncing"
          indeterminate
          color="blue"
          size="15"
        />
        {{ alert?.message }}
      </v-snackbar>
      <v-breadcrumbs :items="$route.meta.breadcrumbs" />

      <!-- <router-view></router-view> -->
      <slot />
    </v-main>
    <v-footer>
      <v-bottom-navigation app grow shift dark class="blue-grey darken-3">
        <v-btn
          v-for="item in opcoes"
          :key="item.value"
          :value="item.value"
          :title="item.hint"
          :to="item.to"
        >
          <span>{{ item.title }}</span>
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  mdiDoorClosedLock,
  mdiHome,
  mdiFormatListText,
  mdiCog,
  mdiOfficeBuildingMarker,
  mdiBrightness2,
  mdiBrightness5
} from '@mdi/js'
import {
  MODULE_NAME as CONFIG,
  SET_CONFIG,
  GET_MODOESCURO,
  ALERT,
  SET_ALERT
} from '@/store/config.js'
import { MODULE_NAME as AUTH, GET_USUARIO_MATRICULA } from '@/store/auth'
import {
  MODULE_NAME as JORNADA,
  GET_HISTORICO_REVERSO,
  GET_JORNADA_INICIADA,
  GET_SYNC
} from '@/store/jornada'
import { RealTimeService, SyncService } from '@/services'
export default {
  name: 'DefaultLayout',
  data() {
    return {
      icons: {
        mdiDoorClosedLock,
        mdiBrightness2,
        mdiBrightness5
      },
      opcoes: [
        {
          value: 'inicio',
          hint: 'Início',
          title: 'Início',
          icon: mdiHome,
          to: '/'
        },
        {
          value: 'historico',
          hint: 'Histórico',
          title: 'Histórico',
          icon: mdiFormatListText,
          to: '/historico'
        },
        {
          value: 'empresa',
          hint: 'Empresa',
          title: 'Empresa',
          icon: mdiOfficeBuildingMarker,
          to: '/empresa'
        },
        {
          value: 'configuracao',
          hint: 'Configuração',
          title: 'Configuração',
          icon: mdiCog,
          to: '/configuracao'
        }
      ],
      color: '',
      // alert: false,
      isOnline: null
    }
  },
  computed: {
    items() {
      return this.opcoes.map(o => ({
        text: o.title
      }))
    },
    [GET_MODOESCURO]() {
      return this.$store.getters[`${CONFIG}/${GET_MODOESCURO}`]
    },
    ...mapGetters(CONFIG, {
      alert: ALERT
    }),
    ...mapGetters(AUTH, {
      userRegistration: GET_USUARIO_MATRICULA
    }),
    ...mapGetters(JORNADA, {
      reverseHistoric: GET_HISTORICO_REVERSO,
      journeyStarted: GET_JORNADA_INICIADA,
      journeys: GET_SYNC
    }),
    showAlert() {
      if (this.alert) {
        return this.alert.show
      } else {
        false
      }
      return false
    }
  },
  watch: {
    color(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.setConfig({
          botaoRedondo: false,
          modoEscuro: newValue
        })
      }
    },
    async isOnline(newValue, oldValue) {
      if (newValue && !oldValue && this.journeyStarted) {
        const RealTime = RealTimeService(this.$store)
        const historic = [...this.reverseHistoric].reverse()
        if (historic.some(item => !item.synced)) {
          this.setSyncingAlert('Sincronizando intervalos...')
        }
        const notSyncedIntervals = []
        for (const interval of historic.filter(item => item.synced === false)) {
          notSyncedIntervals.push(interval)
        }
        await RealTime.synchronize(notSyncedIntervals, this.userRegistration)
        this.setSuccessAlert('Intervalos sincronizados com sucesso!')
      }
      if (newValue && !oldValue) {
        const RealTime = RealTimeService(this.$store)
        const notSyncedJourneys = this.journeys.filter(
          journey => journey.synced === false
        )
        if (notSyncedJourneys.length) {
          this.setSyncingAlert('Sincronizando jornadas...')
        }
        const Service = SyncService(this.$store)
        for (const journey of notSyncedJourneys) {
          const { historico, journeyId } = journey
          if (!historico[0].synced) {
            await Service.synchronize()
          } else {
            const notSyncedIntervals = [...historico.filter(i => !i.synced)]
            const notSyncedJourneyId = journeyId || historico.at(0).journeyId
            await RealTime.synchronize(
              notSyncedIntervals,
              this.userRegistration,
              notSyncedJourneyId
            )
          }
        }
        if (notSyncedJourneys.length) {
          this.setSuccessAlert('Jornadas sincronizadas com sucesso!')
        }
      }
    }
  },
  async created() {
    if (!this.alert) {
      this.setAlertConfig({
        show: false,
        error: false,
        syncing: false,
        message: ''
      })
    }
    this.color = this[GET_MODOESCURO]
    window.addEventListener('online', () => {
      this.isOnline = true
    })
    window.addEventListener('offline', () => {
      this.isOnline = false
    })
    this.isOnline = navigator.onLine
  },
  methods: {
    ...mapActions(CONFIG, { setConfig: SET_CONFIG, setAlertConfig: SET_ALERT }),
    setSyncingAlert(message) {
      this.setAlertConfig({
        show: true,
        error: false,
        syncing: true,
        message
      })
    },
    setSuccessAlert(message) {
      this.setAlertConfig({
        show: true,
        error: false,
        syncing: false,
        message
      })
    },
    async logout() {
      this.$router.push({ name: 'SistemaEntrar' })
    }
  }
}
</script>
